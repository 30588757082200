import type { CustomWindow } from '~/types';

/**
 * Returns the complete API URL, handling both server-side and client-side environments.
 *
 * Required setup for client-side usage:
 * 1. Route must have a loader that uses envLoader
 * 2. Component must use useLoaderData to get env variables
 * 3. Component must call useEnv(env) to inject values into window
 *
 * Example setup in route:
 * ```
 * export const loader = envLoader;
 * const { env } = useLoaderData<typeof loader>();
 * useEnv(env);
 * ```
 *
 * @returns Fully formed API URL string
 * Server-side: Combines API_HOST and API_BASE_URL from process.env
 * Client-side: Combines API_HOST and API_BASE_URL from window object (injected by useEnv)
 *
 * @throws TypeError if environment variables or window properties are undefined
 */

export function getApiUrl(): string {
  if (typeof window === 'undefined') {
    // Server-side: use environment variables
    if (!process.env.API_HOST || !process.env.API_BASE_URL) {
      throw new Error('Missing required environment variables');
    }
    return `${process.env.API_HOST}${process.env.API_BASE_URL}`;
  }

  // Client-side: check if values are available
  const win = window as unknown as CustomWindow;
  if (!win.API_HOST || !win.API_BASE_URL) {
    throw new Error('API configuration not initialized');
  }
  return `${win.API_HOST}${win.API_BASE_URL}`;
}
