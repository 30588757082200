import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState
} from 'react';

interface SoundContextType {
  isSoundEnabled: boolean;
  toggleSound: () => void;
}

const SoundContext = createContext<SoundContextType | undefined>(undefined);

export function SoundProvider({ children }: { children: ReactNode }) {
  const [isSoundEnabled, setIsSoundEnabled] = useState(() => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem('isSoundEnabled');
      return saved ? JSON.parse(saved) : false;
    }
    return false;
  });

  const toggleSound = () => {
    setIsSoundEnabled((prev: boolean) => {
      const newValue = !prev;
      if (typeof window !== 'undefined') {
        localStorage.setItem('isSoundEnabled', JSON.stringify(newValue));
      }
      return newValue;
    });
  };

  return (
    <SoundContext.Provider
      value={useMemo(() => ({ isSoundEnabled, toggleSound }), [isSoundEnabled])}
    >
      {children}
    </SoundContext.Provider>
  );
}

export const useSoundContext = () => {
  const context = useContext(SoundContext);
  if (context === undefined) {
    throw new Error('useSoundContext must be used within a SoundProvider');
  }
  return context;
};
